<template>
    <sidebar-general
        ref="sidebarAgregarDispositivo"
        title="Agregar dispositivo"
        aceptar="Agregar"
        @aceptar="vincularDispositivo"
        :cargando="loading"
    >
        <ValidationObserver ref="form">
            <div class="container">
                <div class="pt-1">
                    <label for="dispositivo">Tipo</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="tipo de dispositivo"
                        rules="required"
                    >
                        <v-select
                            v-model="tipoDispositivoSelected"
                            :options="tiposDispositivos"
                            :reduce="(selected) => selected.id"
                            id="dispositivo"
                            placeholder="Seleccionar"
                            @option:selected="getDispositivosDisponibles"
                        >
                            <template v-slot:no-options>
                                No se encontraron tipos disponibles :(
                            </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }} </small>
                    </ValidationProvider>
                </div>
                <div class="pt-1">
                    <label for="dispositivo">Dispositivo</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="dispositivo"
                        rules="required"
                    >
                        <v-select
                            v-model="dispositivoSelected"
                            :disabled="!tipoDispositivoSelected"
                            :options="dispositivosDisponibles"
                            placeholder="Seleccionar"
                            :reduce="(dispositivo) => dispositivo.id_tipo"
                        >
                        </v-select>
                        <small v-show="!tipoDispositivoSelected"
                            >Selecciona primero un tipo*
                        </small>
                        <p class="text-danger font-small-2 mb-0 pb-0">
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="pt-1">
                    <label for="desde">Desde</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="desde"
                        rules="required"
                    >
                        <b-form-datepicker
                            label-reset-button="Borrar"
                            reset-button
                            v-model="desde"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Fecha"
                        ></b-form-datepicker>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <div class="pt-1">
                    <label for="hasta"
                        >Hasta(dejar vacío si es indefinida)</label
                    >
                    <ValidationProvider v-slot="{ errors }" name="hasta">
                        <b-form-datepicker
                            label-reset-button="Borrar"
                            reset-button
                            v-model="hasta"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Fecha"
                        ></b-form-datepicker>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </sidebar-general>
</template>
<script>
import Pacientes from "@/services/pacientes";
import Dispositivos from "@/services/dispositivos";
import { ref, onMounted } from "@vue/composition-api";
export default {
    setup(props, context) {
        const tipoDispositivoSelected = ref(null);
        const dispositivoSelected = ref(null);
        const idEntidadActual = ref(null);
        const idUser = ref(0);
        const desde = ref(null);
        const hasta = ref(null);
        const loading = ref(false);

        const tiposDispositivos = ref([]);
        const dispositivosDisponibles = ref([]);

        function toggle(params) {
            limpiar();
            idEntidadActual.value = params.id_entidad;
            idUser.value = params.id_user;
            context.refs.sidebarAgregarDispositivo.toggle();
            getTiposDispositivos(params.id_entidad);
        }

        function limpiar() {
            tipoDispositivoSelected.value = null;
            dispositivoSelected.value = null;
            desde.value = null;
            hasta.value = null;
        }

        async function getTiposDispositivos(idEntidad) {
            try {
                const { data } = await Dispositivos.getTiposDispositivos(
                    idEntidad
                );
                tiposDispositivos.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function getDispositivosDisponibles() {
            dispositivoSelected.value = null;
            try {
                const params = {
                    id_entidad: idEntidadActual.value,
                    id_tipo: tipoDispositivoSelected.value,
                };
                const { data } = await Dispositivos.getDispositivosDisponibles(
                    params
                );

                dispositivosDisponibles.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function vincularDispositivo() {
            try {
                const validate = await context.refs.form.validate();

                if (!validate)
                    return context.root.notificacion(
                        "Por favor completa los campos",
                        null,
                        "warning"
                    );

                loading.value = true;
                const params = {
                    id_dispositivo: dispositivoSelected.value,
                    id_entidad: idEntidadActual.value,
                    id_user: idUser.value,
                    fecha_desde: desde.value,
                    fecha_hasta: hasta.value,
                };
                const { data } = await Pacientes.vincularDispositivo(params);

                context.root.notificacion(data.message, null, "success");
                context.emit("update");
                context.refs.sidebarAgregarDispositivo.toggle();
            } catch (error) {
                context.root.catch(error);
            } finally {
                loading.value = false;
            }
        }

        return {
            toggle,
            tipoDispositivoSelected,
            dispositivoSelected,
            desde,
            hasta,
            getDispositivosDisponibles,
            dispositivosDisponibles,
            tiposDispositivos,
            vincularDispositivo,
            loading,
        };
    },
};
</script>
