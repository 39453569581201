var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sidebar-general',{ref:"sidebarAgregarDispositivo",attrs:{"title":"Agregar dispositivo","aceptar":"Agregar","cargando":_vm.loading},on:{"aceptar":_vm.vincularDispositivo}},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pt-1"},[_c('label',{attrs:{"for":"dispositivo"}},[_vm._v("Tipo")]),_c('ValidationProvider',{attrs:{"name":"tipo de dispositivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.tiposDispositivos,"reduce":function (selected) { return selected.id; },"id":"dispositivo","placeholder":"Seleccionar"},on:{"option:selected":_vm.getDispositivosDisponibles},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" No se encontraron tipos disponibles :( ")]},proxy:true}],null,true),model:{value:(_vm.tipoDispositivoSelected),callback:function ($$v) {_vm.tipoDispositivoSelected=$$v},expression:"tipoDispositivoSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"pt-1"},[_c('label',{attrs:{"for":"dispositivo"}},[_vm._v("Dispositivo")]),_c('ValidationProvider',{attrs:{"name":"dispositivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!_vm.tipoDispositivoSelected,"options":_vm.dispositivosDisponibles,"placeholder":"Seleccionar","reduce":function (dispositivo) { return dispositivo.id_tipo; }},model:{value:(_vm.dispositivoSelected),callback:function ($$v) {_vm.dispositivoSelected=$$v},expression:"dispositivoSelected"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tipoDispositivoSelected),expression:"!tipoDispositivoSelected"}]},[_vm._v("Selecciona primero un tipo* ")]),_c('p',{staticClass:"text-danger font-small-2 mb-0 pb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"pt-1"},[_c('label',{attrs:{"for":"desde"}},[_vm._v("Desde")]),_c('ValidationProvider',{attrs:{"name":"desde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"label-reset-button":"Borrar","reset-button":"","state":errors.length > 0 ? false : null,"placeholder":"Fecha"},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"pt-1"},[_c('label',{attrs:{"for":"hasta"}},[_vm._v("Hasta(dejar vacío si es indefinida)")]),_c('ValidationProvider',{attrs:{"name":"hasta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"label-reset-button":"Borrar","reset-button":"","state":errors.length > 0 ? false : null,"placeholder":"Fecha"},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }