import axiosIns from "@/libs/axios";

const api = "dispositivos";

export default {
    getTiposDispositivos: (idEntidad) =>
        axiosIns.get(`${api}/${idEntidad}/tipos`),
    getDispositivosDisponibles: (params) =>
        axiosIns.get(`${api}/disponibles`, { params }),
};
